import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import { verifyOtp } from '../../actions/auth'
import Alert from '../layout/Alert'

const ClientOtp = ({ setShowModal, setAlert, verifyOtp, email, showModal }) => {
	const navigate = useNavigate()
	const [otp, setOtp] = useState('')
	const [loading, setLoading] = useState(false)

	const handleClose = () => {
		setOtp('')
		setShowModal(false)
	}

	const handleOtpSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		if (otp !== '' && otp !== undefined && otp !== null) {
			await verifyOtp(otp.trim(), email, navigate)
		} else {
			setAlert(
				'Please enter the code you recieved in your email',
				'danger',
				'Otp'
			)
		}
		setLoading(false)
	}

	return (
		<Modal
			centered
			show={showModal}
			onHide={handleClose}
			backdrop='static'
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>jobflo 2-step verification code</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleOtpSubmit}>
					<Alert element='Otp' />
					<Form.Label>Verification code</Form.Label>
					<Form.Group className='mb-3'>
						<Form.Control
							value={otp}
							type='text'
							placeholder='6-digit code'
							onChange={(e) => setOtp(e.target.value)}
							required
						/>
					</Form.Group>
					<Form.Group className='mb-3'>
						{!loading ? <Button type='submit'>Submit</Button> : <Spinner />}
					</Form.Group>
				</Form>
			</Modal.Body>
		</Modal>
	)
}

ClientOtp.propTypes = {
	setAlert: PropTypes.func.isRequired,
	verifyOtp: PropTypes.func.isRequired,
	email: PropTypes.string.isRequired,
	setShowModal: PropTypes.func.isRequired,
	showModal: PropTypes.bool.isRequired,
}

export default connect(null, { setAlert, verifyOtp })(ClientOtp)
