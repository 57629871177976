import PropTypes from 'prop-types'
import React from 'react'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import RecordsTable from './table/RecordsTable'

const Jobs = ({ isAuthenticated }) => {
	return (
		<div className='job-view body-padding'>
			<div className='container'>
				{isAuthenticated ? <RecordsTable /> : <Spinner />}
			</div>
		</div>
	)
}

Jobs.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(Jobs)
