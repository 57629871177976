import React from 'react'
import Logo from '../../assets/images/Logo.png'

const Footer = () => {
	return (
		<footer>
			<div className='container'>
				<img
					className='center-wrap'
					src={Logo}
					alt='logo'
					style={{ height: '72px', width: '168px' }}
				/>
			</div>
		</footer>
	)
}

export default Footer
