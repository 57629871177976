import {
	GET_JOB,
	GET_JOBS,
	GET_JOBS_HISTORY,
	INIT_JOBS,
	JOB_ERROR,
	LOADING_JOBS,
	SELECT_JOBS,
} from '../actions/types'

const initialState = {
	jobs: [],
	job: null,
	selectedJobs: [],
	loading: true,
	error: '',
}

export default function foo(state = initialState, action) {
	const { type, payload } = action
	switch (type) {
		case GET_JOBS:
			return {
				...state,
				jobs: payload.sort((a, b) => {
					let da = new Date(a.date),
						db = new Date(b.date)
					return db - da
				}),
				loading: false,
				error: '',
			}
		case GET_JOBS_HISTORY:
			return {
				...state,
				jobs: payload.sort((a, b) => {
					let da = new Date(a.date),
						db = new Date(b.date)
					return db - da
				}),
				loading: false,
				error: '',
			}
		case GET_JOB:
			return {
				...state,
				job: payload,
				loading: false,
				error: '',
			}
		case SELECT_JOBS:
			return {
				...state,
				selectedJobs: payload,
				error: '',
			}
		case INIT_JOBS:
			return {
				...state,
				jobs: [],
				job: null,
				selectedJobs: [],
				loading: true,
				error: '',
			}
		case JOB_ERROR:
			return {
				...state,
				jobs: [],
				job: null,
				selectedJobs: [],
				loading: false,
				error: payload,
			}
		case LOADING_JOBS:
			return {
				...state,
				loading: true,
			}
		default:
			return state
	}
}
