import { combineReducers } from 'redux'
import alert from './alert'
import auth from './auth'
import jobs from './jobs'

export default combineReducers({
	alert,
	auth,
	job: jobs,
})
