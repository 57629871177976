import 'bootstrap/dist/css/bootstrap.min.css'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { initUser, logout } from './actions/auth'
import ChangePassword from './components/ChangePassword'
import ForgotPassword from './components/ForgotPassword'
import Jobs from './components/Jobs'
import PrivateRoute from './components/PrivateRoute'
import ResetPassword from './components/ResetPassword'
import Login from './components/auth/Login'
import Footer from './components/layout/Footer'
import Header from './components/layout/Header'
import NotFound from './components/layout/NotFound'
import useGaTracker from './utils/useGaTracker'

function App({ auth, initUser, logout }) {
	useEffect(() => {
		initUser()
	}, [initUser])

	useGaTracker()

	window.onunload = () => {
		if (!auth.remember) {
			logout()
		}
	}

	return (
		<div className='App'>
			<Header />
			<div id='body-wrapper'>
				<Routes>
					<Route
						path='/'
						element={
							<PrivateRoute>
								<Jobs />
							</PrivateRoute>
						}
					/>
					<Route path='/login' element={<Login />} />
					<Route
						path='/jobs'
						element={
							<PrivateRoute>
								<Jobs />
							</PrivateRoute>
						}
					/>

					<Route
						path='/profile/changepassword'
						element={
							<PrivateRoute>
								<ChangePassword />
							</PrivateRoute>
						}
					/>
					<Route path='/forgotpassword' element={<ForgotPassword />} />
					<Route
						path='/clients/password/reset/:token'
						element={<ResetPassword />}
					/>

					<Route path='*' element={<NotFound />} />
				</Routes>
			</div>
			<Footer />
		</div>
	)
}

App.propTypes = {
	initUser: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	auth: state.auth,
})

export default connect(mapStateToProps, { initUser, logout })(App)
