import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { setAlert } from '../actions/alert'
import AuthService from '../services/auth.service'
import Alert from './layout/Alert'

const ResetPassword = ({ setAlert }) => {
	const navigate = useNavigate()
	const { token } = useParams()
	const [formData, setFormData] = useState({
		new_password: '',
		confirm_password: '',
	})
	const [loading, setLoading] = useState(false)

	const { new_password, confirm_password } = formData

	useEffect(() => {
		if (token === null || token === undefined || token === '') {
			navigate('/')
		}
	}, [])

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (new_password !== '' && confirm_password !== '') {
			if (new_password !== confirm_password) {
				setAlert(`passwords don't match`, 'danger', 'ResetPassword')
			} else {
				setLoading(true)
				try {
					const res = await AuthService.resetPassword(formData, token)
					setAlert(res.data.response, 'success', 'ResetPassword')
					setTimeout(() => navigate('/'), 1500)
				} catch (err) {
					setAlert('Failed to reset password!', 'danger', 'ResetPassword')
				} finally {
					setFormData({
						new_password: '',
						confirm_password: '',
					})
					setLoading(false)
				}
			}
		} else {
			setAlert('Please fill the required fields', 'danger', 'ResetPassword')
		}
	}

	return (
		<div className='client-container container'>
			<h1 className='title'>Reset Password</h1>
			<form className='signup-form' onSubmit={handleSubmit}>
				<Alert element='ResetPassword' />

				{!loading ? (
					<>
						<div className='item'>
							<label htmlFor='new_password'>New Password</label>
							<input
								onChange={handleChange}
								name='new_password'
								value={new_password}
								type='password'
								required
							/>
						</div>
						<div className='item'>
							<label htmlFor='confirm_password'>Confirm Password</label>
							<input
								onChange={handleChange}
								name='confirm_password'
								value={confirm_password}
								type='password'
								required
							/>
						</div>
						<div className='item'>
							<div></div>
							<input type='submit' value='Confirm' />
						</div>
					</>
				) : (
					<Spinner />
				)}
			</form>
		</div>
	)
}

ResetPassword.propTypes = {
	setAlert: PropTypes.func.isRequired,
}

export default connect(null, { setAlert })(ResetPassword)
