import { v4 as uuid } from 'uuid'
import { REMOVE_ALERT, SET_ALERT } from './types'

export const setAlert =
	(msg, alertType, element, timeout = 4000) =>
	(dispatch) => {
		const id = uuid()
		dispatch({ type: SET_ALERT, payload: { msg, alertType, id, element } })

		setTimeout(() => {
			dispatch({ type: REMOVE_ALERT, payload: id })
		}, timeout)
	}

export const removeAlert = (id) => (dispatch) => {
	dispatch({ type: REMOVE_ALERT, payload: id })
}
