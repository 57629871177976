import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'


const useGaTracker = () => {
	const location = useLocation()
	const [initialized, setInitialized] = useState(false)

	useEffect(() => {
		if (!initialized) {
			ReactGA.initialize('UA-228530790-1')
            setInitialized(true)
		}
	}, [])

	useEffect(() => {
		if (initialized) {
			ReactGA.pageview(location.pathname + location.search)
		}
	}, [initialized, location])
}

export default useGaTracker
