import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
	return (
		<div className='container m-6'>
			<h1>
				404 Error <br /> Page Not Found
			</h1>

			<Link to='/'>
				Go Back home
			</Link>
		</div>
	)
}

export default NotFound
