import JobServices from '../services/job.service'
import { setAlert } from './alert'
import { GET_JOB, GET_JOBS, GET_JOBS_HISTORY, INIT_JOBS, JOB_ERROR, LOADING_JOBS, SELECT_JOBS } from './types'

export const getJobs = () => async (dispatch) => {
	dispatch({type: LOADING_JOBS})
	try {
		const res = await JobServices.getAllJobs()
		dispatch({ type: GET_JOBS, payload: res.data })
	} catch (err) {
		dispatch({ type: JOB_ERROR, payload: err.message })
	}
}

export const getJobsHistory = () => async (dispatch) => {
	dispatch({type: LOADING_JOBS})
	try {
		const res = await JobServices.getJobsHistory()
		dispatch({ type: GET_JOBS_HISTORY, payload: res.data })
	} catch (err) {
		dispatch({ type: JOB_ERROR, payload: err.message })
		dispatch(setAlert('Failed to get jobs history!', 'danger', 'Filters'))
	}
}

export const getFilteredJobs = (formData) => async (dispatch) => {
	dispatch({type: LOADING_JOBS})
	try {
		const res = await JobServices.getFilteredJobs(formData)
		dispatch({ type: GET_JOBS, payload: res.data })
	} catch (err) {
		dispatch({ type: JOB_ERROR, payload: err.message })
		dispatch(setAlert(err.message, 'danger', 'Filters'))
	}
}

export const getJob = (id) => async (dispatch) => {
	try {
		const res = await JobServices.getJob(id)
		dispatch({ type: GET_JOB, payload: res.data })
	} catch (err) {
		dispatch({ type: JOB_ERROR, payload: err.message })
	}
}

export const selectJobs = (selectedJobs) => (dispatch) => {
	dispatch({ type: SELECT_JOBS, payload: selectedJobs })
}

export const initJobs = () => (dispatch) => {
	dispatch({ type: INIT_JOBS })
}
