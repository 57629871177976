import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../actions/alert'
import { logout } from '../actions/auth'
import AuthService from '../services/auth.service'
import Alert from './layout/Alert'

const ChangePassword = ({ user, setAlert, logout }) => {
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		password: '',
		new_password: '',
		confirm_password: '',
	})
	const [loading, setLoading] = useState(false)

	const { password, new_password, confirm_password } = formData

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		if (password !== '' && new_password !== '' && confirm_password !== '') {
			if (new_password !== confirm_password) {
				setAlert(
					`new password and confirm password don't match`,
					'danger',
					'ChangePassword'
				)
			} else {
				setLoading(true)
				try {
					const res = await AuthService.changePassword(formData)
					setAlert(res.data.response, 'success', 'ChangePassword')
					setTimeout(() => logout(navigate), 1500)
				} catch (err) {
					setAlert('Old password is wrong!', 'danger', 'ChangePassword')
				} finally {
					setFormData({
						password: '',
						new_password: '',
						confirm_password: '',
					})
					setLoading(false)
				}
			}
		} else {
			setAlert('Please fill the required fields', 'danger', 'ChangePassword')
		}
	}

	return (
		<div className='container body-padding'>
			<span className='pb-3 login-title'>Change password</span>
			<Form onSubmit={handleSubmit} className='w20'>
				<Alert element='ChangePassword' />

				<Form.Group className='mb-3'>
					<Form.Label>Current password</Form.Label>
					<Form.Control
						value={password}
						name='password'
						type='password'
						placeholder='Password'
						onChange={handleChange}
						required
					/>
				</Form.Group>

				<Form.Group className='mb-3'>
					<Form.Label>New password</Form.Label>
					<Form.Control
						value={new_password}
						name='new_password'
						type='password'
						placeholder='New password'
						onChange={handleChange}
						required
					/>
				</Form.Group>

				<Form.Group className='mb-3'>
					<Form.Label>Confirm password</Form.Label>
					<Form.Control
						value={confirm_password}
						name='confirm_password'
						type='password'
						placeholder='Confirm password'
						onChange={handleChange}
						required
					/>
				</Form.Group>

				<Form.Group className='mb-3'>
					<Button className='login-submit' type='submit'>
						Submit
					</Button>
				</Form.Group>
			</Form>
		</div>
	)
}

ChangePassword.propTypes = {
	setAlert: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	user: state.auth.user,
})

export default connect(mapStateToProps, { setAlert, logout })(ChangePassword)
