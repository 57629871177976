export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const REMEMBER_ME = 'REMEMBER_ME'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const GET_JOBS = 'GET_JOBS'
export const GET_JOBS_HISTORY = 'GET_JOBS_HISTORY'
export const GET_JOB = 'GET_JOB'
export const SELECT_JOBS = 'SELECT_JOBS'
export const INIT_JOBS = 'INIT_JOBS'
export const JOB_ERROR = 'JOB_ERROR'
export const LOADING_JOBS = 'LOADING_JOBS'
