import ReactGA from 'react-ga'
import AuthService from '../services/auth.service'
import { setAlert } from './alert'
import {
	AUTH_ERROR,
	LOGIN_FAIL,
	LOGIN_SUCCESS,
	LOGOUT,
	REMEMBER_ME,
	USER_LOADED,
} from './types'

// Load User
export const loadUser = () => (dispatch) => {
	dispatch({ type: USER_LOADED, payload: AuthService.getCurrentUser() })
}

// Init User
export const initUser = () => async (dispatch) => {
	const res = await AuthService.refreshToken()
	if (res === true) {
		dispatch(loadUser())
		ReactGA.set({ userId: AuthService.getCurrentUser().id })
	} else {
		AuthService.logout()
		dispatch({ type: LOGIN_FAIL, payload: 'Token has expired!' })
	}
}

// Login
export const login = (email, password, remember) => async (dispatch) => {
	try {
		const res = await AuthService.login(email, password)
		if (res.data.status === 'success') {
			if (remember) dispatch({ type: REMEMBER_ME })
			return true
		}
		return false
	} catch (err) {
		dispatch({ type: LOGIN_FAIL, payload: err.message })
		dispatch(setAlert('Invalid Credentials!', 'danger', 'Login'))
	}
}

// Verify Otp
export const verifyOtp = (otp, email, navigate) => async (dispatch) => {
	try {
		await AuthService.verifyOtp(otp, email)
		dispatch({ type: LOGIN_SUCCESS })
		dispatch(loadUser())
		ReactGA.set({ userId: AuthService.getCurrentUser().id })
		navigate('/jobs')
	} catch (err) {
		dispatch({ type: LOGIN_FAIL, payload: err.message })
		dispatch(setAlert('Invalid Otp!', 'danger', 'Otp'))
	}
}

// Logout
export const logout = () => async (dispatch) => {
	try {
		await Promise.all([
			AuthService.revokeRefreshToken(),
			AuthService.revokeAccessToken(),
		])
		AuthService.logout()
		dispatch({ type: LOGOUT })
	} catch (err) {
		dispatch({ type: AUTH_ERROR })
	}
}
