import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'

import format from 'date-fns/format'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './table/table.css'

const DateRangePickerComp = ({ range, setRange, allowFilterByDate }) => {
	const [open, setOpen] = useState(false)

	const refOne = useRef(null)

	useEffect(() => {
		// event listeners
		document.addEventListener('keydown', hideOnEscape, true)
		document.addEventListener('click', hideOnClickOutside, true)
	}, [])

	// hide dropdown on ESC press
	const hideOnEscape = (e) => {
		if (e.key === 'Escape') {
			setOpen(false)
		}
	}

	// Hide dropdown on outside click
	const hideOnClickOutside = (e) => {
		if (refOne.current && !refOne.current.contains(e.target)) {
			setOpen(false)
		}
	}

	return (
		<div className='col-6'>
			{range && (
				<>
					<input
						disabled={allowFilterByDate}
						value={`${format(range[0].startDate, 'dd/MM/yyyy')} to ${format(
							range[0].endDate,
							'dd/MM/yyyy'
						)}`}
						readOnly
						style={{width: '100%'}}
						className='form-control'
						onClick={() => setOpen((open) => !open)}
					/>

					<div style={{position: 'absolute', zIndex: '1000'}} ref={refOne}>
						{open && (
							<DateRange
								onChange={(item) => setRange([item.selection])}
								editableDateInputs={true}
								moveRangeOnFirstSelection={true}
								ranges={range}
								months={1}
								direction='vertical'
								className='calendarElement'
							/>
						)}
					</div>
				</>
			)}
		</div>
	)
}

DateRangePickerComp.propTypes = {
	range: PropTypes.array.isRequired,
	setRange: PropTypes.func.isRequired,
	allowFilterByDate: PropTypes.bool.isRequired,
}

export default DateRangePickerComp
