import api from '../utils/api'

const getAllJobs = () => {
	return api.get('/jobs/clients/findall')
}

const getFilteredJobs = (formData) => {
	return api.post('/jobs/client/search', JSON.stringify(formData))
}

const getJob = (id) => {
	return api.get(`/jobs/find/${id}`)
}

const getJobsHistory = () => {
	return api.get('/jobs_history/findall')
}

const addSearch = (formData) => {
	return api.post('/search/add', JSON.stringify(formData))
}

const removeSearch = (id) => {
	return api.delete(`/search/remove/${id}`)
}

const updateSearchAlert = (alertStatus) => {
	return api.put(`/search/update/alert`, JSON.stringify({ alert: alertStatus }))
}

const getAllSearches = () => {
	return api.get('/search/clients/findall')
}

const getAlertStatus = () => {
	return api.get('/search/alert')
}

const getCompanies = () => {
	return api.get('/companies/findall')
}

const getLocations = () => {
	return api.get('/locations/findall')
}

const getSectors = () => {
	return api.get('sectors/findall')
}

const JobServices = {
	getAllJobs,
	getJob,
	addSearch,
	removeSearch,
	updateSearchAlert,
	getAllSearches,
	getAlertStatus,
	getFilteredJobs,
	getCompanies,
	getLocations,
	getSectors,
	getJobsHistory,
}

export default JobServices
