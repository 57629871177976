import PropTypes from 'prop-types'
import React from 'react'
import Alert from 'react-bootstrap/Alert'
import { connect } from 'react-redux'
import { removeAlert } from '../../actions/alert'

const AlertComp = ({ alerts, element, removeAlert }) =>
	alerts !== null &&
	alerts.length > 0 &&
	alerts.map(
		(alert) =>
			alert.element === element && (
				<Alert
					key={alert.id}
					variant={alert.alertType}
					onClose={() => removeAlert(alert.id)}
					dismissible
				>
					{alert.msg}
				</Alert>
			)
	)

AlertComp.propTypes = {
	alerts: PropTypes.array.isRequired,
	element: PropTypes.string.isRequired,
	removeAlert: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	alerts: state.alert,
})

export default connect(mapStateToProps, { removeAlert })(AlertComp)
