import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import { login } from '../../actions/auth'
import Alert from '../layout/Alert'
import ClientOtp from './ClientOtp'

const Login = ({ isAuthenticated, setAlert, login }) => {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [rememberMe, setRememberMe] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		isAuthenticated && navigate('/jobs')
	}, [isAuthenticated, navigate])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (email !== '' && password !== '') {
			setLoading(true)
			const res = await login(email, password, rememberMe)
			if (res) {
				setShowModal(true)
			}
		} else {
			setAlert('Missing fields!', 'danger', 'Login')
		}
		setLoading(false)
	}

	return (
		<div className='container body-padding'>
			<span className='pb-3 login-title'>
				Enter your email address and password to login
			</span>
			<Form onSubmit={handleSubmit} className='w20'>
				<Alert element='Login' />
				<Form.Group className='mb-3' controlId='formHorizontalEmail'>
					<Form.Label>Email</Form.Label>

					<Form.Control
						value={email}
						type='email'
						placeholder='Email'
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
				</Form.Group>

				<Form.Group className='mb-3' controlId='formHorizontalPassword'>
					<Form.Label>Password</Form.Label>

					<Form.Control
						value={password}
						type='password'
						placeholder='Password'
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
				</Form.Group>

				<Form.Group as={Row} className='mb-3' controlId='formHorizontalCheck'>
					<Col>
						<Form.Check
							value='rememberMe'
							label='Keep logged in'
							onChange={() => setRememberMe(!rememberMe)}
						/>
					</Col>
					<Col style={{ textAlign: 'right' }}>
						<Form.Text>
							<Link to='/forgotpassword'>Forgot password?</Link>
						</Form.Text>
					</Col>
				</Form.Group>

				<Form.Group className='mb-3 flex justify-content-center'>
					{!loading ? (
						<Button className='login-submit' type='submit'>
							Submit
						</Button>
					) : (
						<Spinner />
					)}
				</Form.Group>
			</Form>

			<ClientOtp
				setShowModal={setShowModal}
				email={email}
				showModal={showModal}
			/>
		</div>
	)
}

Login.propTypes = {
	setAlert: PropTypes.func.isRequired,
	login: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, { setAlert, login })(Login)
