import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Alert from './layout/Alert'

const SavedSearchModal = ({
	setShowModal,
	showModal,
	modalType,
	addSearch,
	removeSearch,
}) => {
	const [searchLabel, setSearchLabel] = useState('')

	const handleClose = () => {
		setSearchLabel('')
		setShowModal(false)
	}

	const handleSubmit = () => {
		addSearch(searchLabel)
		setSearchLabel('')
		setShowModal(false)
	}

	const handleRemoveSearch = () => {
		removeSearch()
		setShowModal(false)
	}

	return (
		<Modal
			centered
			show={showModal}
			onHide={handleClose}
			backdrop='static'
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{modalType === 'add' ? 'Save Search' : 'Delete Search'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{modalType === 'add' ? (
					<Form>
						<Alert element='Otp' />
						<Form.Label>Save as</Form.Label>
						<Form.Group className='mb-3'>
							<Form.Control
								value={searchLabel}
								type='text'
								placeholder='search label'
								onChange={(e) => setSearchLabel(e.target.value)}
								required
							/>
						</Form.Group>
					</Form>
				) : (
					<p>Are you sure you want to delete this search ?</p>
				)}

				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Close
					</Button>
					{modalType === 'add' ? (
						<Button variant='primary' onClick={handleSubmit}>
							Submit
						</Button>
					) : (
						<Button variant='danger' onClick={handleRemoveSearch}>
							Delete
						</Button>
					)}
				</Modal.Footer>
			</Modal.Body>
		</Modal>
	)
}

SavedSearchModal.propTypes = {
	modalType: PropTypes.string.isRequired,
	addSearch: PropTypes.func.isRequired,
	removeSearch: PropTypes.func.isRequired,
}

export default SavedSearchModal
