import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { BsCaretDownSquareFill, BsCaretUpSquareFill } from 'react-icons/bs'
import { GrFormNext, GrFormPrevious } from 'react-icons/gr'
import { connect } from 'react-redux'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from 'react-table'
import { setAlert } from '../../actions/alert'
import { getFilteredJobs, getJobs, getJobsHistory } from '../../actions/jobs'
import JobServices from '../../services/job.service'
import DateRangePickerComp from '../DateRangePickerComp'
import SavedSearchModal from '../SavedSearchModal'
import Alert from '../layout/Alert'
import { COLUMNS } from './Columns'
import './table.css'

const RecordsTable = ({
	job: { jobs, loading },
	setAlert,
	getFilteredJobs,
	getJobs,
	getJobsHistory,
	is_premium,
}) => {
	const columns = useMemo(() => COLUMNS, [])
	const [data, setData] = useState([])
	const [jobTitle, setJobTitle] = useState('')
	const [locations, setLocations] = useState([])
	const [locationsOptions, setLocationsOptions] = useState([])
	const [sectors, setSectors] = useState([])
	const [sectorsOptions, setSectorsOptions] = useState([])
	const [dataToExport, setDataToExport] = useState([])
	const [headerToExport, setHeaderToExport] = useState([])
	const [savedSearchesOptions, setSavedSearchesOptions] = useState([])
	const [savedSearches, setSavedSearches] = useState([])
	const [selectedSearch, setSelectedSearch] = useState({})
	const [emailAlert, setEmailAlert] = useState(false)
	const [dateSwitch, setDateSwitch] = useState(false)
	const [notLocation, setNotLocation] = useState(false)
	const [notSector, setNotSector] = useState(false)
	const [notJobTitle, setNotJobTitle] = useState('')
	const csvLink = useRef()
	const [range, setRange] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	])
	const [source, setSource] = useState('')
	const [allowFilterByDate, setAllowFilterByDate] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [modalType, setModalType] = useState('')

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		visibleColumns,
		flatRows,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		prepareRow,
		setFilter,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { hiddenColumns: ['id', 'source'] },
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect
	)

	useEffect(() => {
		// get jobs
		getJobs()

		// get saved searches
		getSearches()

		const getFiltersOptions = async () => {
			const [locationsRes, sectorsRes] = await Promise.all([
				JobServices.getLocations(),
				JobServices.getSectors(),
			])

			// Fill locations options
			setLocationsOptions(
				locationsRes.data.map((e) => {
					return { value: e.location_name, label: e.location_name }
				})
			)

			// Fill sectors options
			setSectorsOptions(
				sectorsRes.data.map((e) => {
					return { value: e.id, label: e.description }
				})
			)
		}
		getFiltersOptions()
	}, [])

	useEffect(() => {
		setData(jobs)
	}, [jobs])

	const getSearches = async () => {
		try {
			const res = await JobServices.getAllSearches()
			if (res.data.length > 0) {
				setSavedSearches(res.data)
				setSavedSearchesOptions(
					res.data.map((s) => ({ label: s.label, value: s.id }))
				)
				setEmailAlert(res.data[0].alert)
			}
		} catch (err) {
			setAlert(err.message, 'danger', 'SavedSearches')
		}
	}

	const handleEmailAlert = async () => {
		try {
			await JobServices.updateSearchAlert(!emailAlert)
			getSearches()
		} catch (err) {
			setAlert(err.message, 'danger', 'SavedSearches')
		}
	}

	const handleDateSwitch = () => {
		if (!dateSwitch) {
			const today = new Date()
			setFilter('date', format(today, 'yyyy-MM-dd'))
		} else {
			setFilter('date', '')
		}
		setDateSwitch(!dateSwitch)
	}

	const addSearch = async (searchLabel) => {
		if (savedSearches.length > 9) {
			setAlert('Maximum saved searches is 10!', 'danger', 'SavedSearches')
		} else {
			if (searchLabel === '') {
				setAlert('Missing search label!', 'danger', 'SavedSearches')
			} else {
				const formData = {
					label: searchLabel,
					...prepareFiltersData(),
				}
				try {
					const res = await JobServices.addSearch(formData)
					setAlert(res.data.response, 'success', 'SavedSearches')
					getSearches()
				} catch (err) {
					setAlert(err.message, 'danger', 'SavedSearches')
				} finally {
					setModalType('')
				}
			}
		}
	}

	const removeSearch = async () => {
		if (selectedSearch.value) {
			try {
				const res = await JobServices.removeSearch(selectedSearch.value)
				setAlert(res.data.response, 'success', 'SavedSearches')
				setSelectedSearch({})
				setSavedSearchesOptions([])
				setSavedSearches([])
				getSearches()
				handleReset()
			} catch (err) {
				setAlert(err.message, 'danger', 'SavedSearches')
			} finally {
				setModalType('')
			}
		}
	}

	const prepareFiltersData = () => {
		const startDate = range[0].startDate
		const endDate = range[0].endDate
		startDate.setHours(0, 0, 0, 0)
		endDate.setHours(23, 59, 59, 999)

		return {
			location_names: locations.map((l) => l.value),
			sectors: sectors.map((s) => s.value),
			job_title: jobTitle,
			not_location: notLocation,
			not_sector: notSector,
			not_job_title: notJobTitle,
			start_date: allowFilterByDate ? startDate.toLocaleString() : '',
			end_date: allowFilterByDate ? endDate.toLocaleString() : '',
		}
	}

	const prepareSearchData = (search) => ({
		location_names: search.location_names,
		sectors: search.sectors,
		job_title: search.job_title,
		not_location: search.not_location,
		not_sector: search.not_sector,
		not_job_title: search.not_job_title,
		start_date: search.start_date,
		end_date: search.end_date,
	})

	const fillFilters = (searchId) => {
		const search = savedSearches.find((s) => s.id === searchId)
		if (search) {
			// get search jobs
			getFilteredJobs(prepareSearchData(search))

			// fill filters
			setLocations(search.location_names.map((e) => ({ label: e, value: e })))
			setSectors(
				search.sectors_obj.map((e) => ({ label: e.description, value: e.id }))
			)
			setJobTitle(search.job_title)
			setNotJobTitle(search.not_job_title)
			setNotLocation(search.not_location)
			setNotSector(search.not_sector)
			if (
				search.start_date &&
				search.start_date !== '' &&
				search.end_date &&
				search.end_date !== ''
			) {
				setAllowFilterByDate(true)
				setRange([
					{
						startDate: new Date(search.start_date),
						endDate: new Date(search.end_date),
						key: 'selection',
					},
				])
			} else {
				setAllowFilterByDate(false)
			}
		}
	}

	const handleReset = () => {
		setJobTitle('')
		setNotJobTitle('')
		setLocations([])
		setSectors([])
		setNotLocation(false)
		setNotSector(false)
		setSource('')
		setFilter('source', '')
		setRange([
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection',
			},
		])
		setAllowFilterByDate(false)
		setDateSwitch(false)
		setFilter('date', '')
		setSelectedSearch({})

		// get all jobs
		getJobs()
	}

	const handleExport = () => {
		const columns = visibleColumns.map((c) => {
			return { label: c.Header, key: c.id }
		})
		const rowsData = flatRows.map((r) => r.original)
		setHeaderToExport(columns)
		setDataToExport(rowsData)
	}

	// Refetch data after changing allowFilterByDate switch to false
	useEffect(() => {
		if (allowFilterByDate === false) getFilteredJobs(prepareFiltersData())
	}, [allowFilterByDate])

	useEffect(() => {
		if (dataToExport.length && headerToExport.length) {
			setTimeout(() => {
				csvLink.current.link.click()
			}, 1)
		}
	}, [dataToExport, headerToExport])

	return (
		<React.Fragment>
			<div className='container row'>
				<div className='col-md-6 order-2 order-md-1'>
					<Alert element='Filters' />
					<div className='form-group mb-2 align-items-center'>
						<label className='col-2'>job title</label>
						<input
							style={{ marginLeft: '0.2rem' }}
							className='col-4 form-input'
							value={jobTitle}
							onChange={(e) => setJobTitle(e.target.value)}
						/>
						<label style={{ margin: '0 0.5rem' }}>NOT</label>
						<input
							className='col-4 form-input'
							type='text'
							value={notJobTitle}
							onChange={(e) => setNotJobTitle(e.target.value)}
						/>
					</div>
					<div className='form-group row mb-2 align-items-center'>
						<label className='col-lg-2'>sector</label>
						<div className='col-lg-10'>
							<Select
								components={makeAnimated()}
								className='col-10 inline-block'
								onChange={setSectors}
								value={sectors}
								options={sectorsOptions}
								placeholder='add a sector to filter'
								noOptionsMessage={() => 'no more sectors to choose from'}
								isMulti
								isSearchable
							/>
							<input
								id='switch-not-sector-alert'
								className='col-1'
								type='checkbox'
								checked={notSector}
								onChange={() => setNotSector(!notSector)}
							/>
							<label className='col-1' htmlFor='switch-not-sector-alert'>
								NOT
							</label>
						</div>
					</div>
					<div className='form-group row mb-2 align-items-center '>
						<label className='col-lg-2'>location</label>
						<div className='col-lg-10'>
							<Select
								components={makeAnimated()}
								className='col-10 inline-block'
								onChange={setLocations}
								value={locations}
								options={locationsOptions}
								placeholder='add a location to filter'
								noOptionsMessage={() => 'no more locations to choose from'}
								isMulti
								isSearchable
							/>
							<input
								id='switch-not-location-alert'
								className='col-1'
								type='checkbox'
								checked={notLocation}
								onChange={() => setNotLocation(!notLocation)}
							/>
							<label className='col-1' htmlFor='switch-not-location-alert'>
								NOT
							</label>
						</div>
					</div>
					<div className='form-group row mb-2 align-items-center'>
						<label className='col-md-2'>date</label>
						<DateRangePickerComp
							range={range}
							setRange={setRange}
							allowFilterByDate={!allowFilterByDate}
						/>
						<div className='form-check form-switch col'>
							<input
								className='form-check-input'
								type='checkbox'
								role='switch'
								id='switch-allow-date'
								onChange={() => setAllowFilterByDate(!allowFilterByDate)}
								checked={allowFilterByDate}
							/>
							<label className='form-check-label' htmlFor='switch-allow-date'>
								filter by date
							</label>
						</div>
					</div>

					<div className='form-group mt-4 mb-2'>
						<div className='col-2 d-none d-lg-inline-block'></div>
						<button
							className='button mr-2 btn-equal-width'
							onClick={() => getFilteredJobs(prepareFiltersData())}
						>
							Search
						</button>
						<button
							className='button mr-2 btn-equal-width'
							onClick={handleReset}
						>
							Reset
						</button>
						<button
							className='button mr-2 btn-equal-width'
							onClick={() => {
								setModalType('add')
								setModalIsOpen(true)
							}}
						>
							Save
						</button>
						{is_premium && (
							<button className='button' onClick={() => getJobsHistory()}>
								jobs history
							</button>
						)}
					</div>
				</div>
				<div className='col-md-6 order-1 order-md-2 mb-3'>
					<Alert element='SavedSearches' />
					<SavedSearchModal
						showModal={modalIsOpen}
						setShowModal={setModalIsOpen}
						addSearch={addSearch}
						removeSearch={removeSearch}
						modalType={modalType}
					/>
					<div className='form-group row align-items-center mb-3'>
						<label className='form-label align-self-end col-md-3'>
							saved searches
						</label>
						<Select
							components={makeAnimated()}
							className='col-6'
							onChange={(v) => {
								if (v !== null && v !== undefined) {
									setSelectedSearch(v)
									fillFilters(v.value)
								} else {
									setSelectedSearch({})
								}
							}}
							value={selectedSearch}
							options={savedSearchesOptions}
							placeholder='saved searches'
							isSearchable
						/>
						<button
							className='btn btn-secondary col-3 btn-equal-width'
							onClick={() => {
								if (selectedSearch.value) {
									setModalType('remove')
									setModalIsOpen(true)
								} else {
									setAlert(
										'no saved search selected!',
										'danger',
										'SavedSearches'
									)
								}
							}}
						>
							delete
						</button>
					</div>
					{savedSearches.length > 0 && (
						<div className='form-group align-items-center'>
							<label className='form-label col-3'>receive emails</label>
							<div className='form-check col-9 form-switch align-items-center inline-block'>
								<input
									className='form-check-input'
									type='checkbox'
									role='switch'
									id='flexSwitchCheckEmail'
									onChange={handleEmailAlert}
									checked={emailAlert}
								/>
								<label
									className='form-check-label'
									htmlFor='flexSwitchCheckEmail'
								>
									ON/OFF
								</label>
							</div>
						</div>
					)}
				</div>
			</div>
			{!loading ? (
				<>
					<div className='static-filters-container mt-3 mb-2'>
						<div className='form-check form-switch'>
							<label
								className='form-check-label'
								htmlFor='flexSwitchCheckDefault'
							>
								show only today's jobs
							</label>
							<input
								className='form-check-input'
								type='checkbox'
								role='switch'
								id='flexSwitchCheckDefault'
								onChange={handleDateSwitch}
								checked={dateSwitch}
							/>
						</div>

						<div className='switchbox-container'>
							<label htmlFor='source'>source</label>
							<select
								style={{ marginLeft: '.2rem' }}
								name='source'
								value={source}
								onChange={(e) => {
									setSource(e.target.value)
									setFilter('source', e.target.value)
								}}
							>
								<option value=''>All</option>
								<option value='direct'>Direct</option>
								<option value='board'>Job board</option>
							</select>
						</div>
					</div>
					<div className=' flex align-items-center justify-content-between pb-2'>
						<div className='flex align-items-center'>
							<span className='d-none d-sm-block mr-2'>
								Showing 1-{pageSize} of {pageCount}
							</span>
							<button className='button' onClick={handleExport}>
								Export
							</button>
							<CSVLink
								data={dataToExport}
								headers={headerToExport}
								filename='jobs-data.csv'
								className='hidden'
								ref={csvLink}
								target='_blank'
							/>
						</div>

						<div className='pagination'>
							<span>
								<select
									value={pageSize}
									onChange={(e) => setPageSize(Number(e.target.value))}
								>
									{[10, 25, 50, 100, 500].map((pageSize) => (
										<option key={pageSize} value={pageSize}>
											{pageSize} per page
										</option>
									))}
								</select>
							</span>
							<span>
								<select
									value={pageIndex}
									onChange={(e) => gotoPage(Number(e.target.value))}
								>
									{[...Array(pageCount)].map((val, i) => (
										<option key={i} value={i}>
											page {i + 1}
										</option>
									))}
								</select>
								<span>of {pageOptions.length}</span>
							</span>{' '}
							<button
								onClick={() => previousPage()}
								disabled={!canPreviousPage}
							>
								<GrFormPrevious size='16px' />
							</button>
							<button onClick={() => nextPage()} disabled={!canNextPage}>
								<GrFormNext size='16px' />
							</button>
						</div>
					</div>
					<div className='table-container'>
						<table {...getTableProps()}>
							<thead>
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th
												{...column.getHeaderProps(
													column.getSortByToggleProps()
												)}
											>
												{column.render('Header')}

												{column.isSorted ? (
													column.isSortedDesc ? (
														<BsCaretDownSquareFill
															fill='#247bff'
															style={{
																marginLeft: '.2em',
																marginBottom: '-.1em',
															}}
														/>
													) : (
														<BsCaretUpSquareFill
															fill='#247bff'
															style={{
																marginLeft: '.2em',
																marginBottom: '-.1em',
															}}
														/>
													)
												) : (
													''
												)}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{page.map((row) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return (
													<td {...cell.getCellProps()}>
														{cell.render('Cell')}
													</td>
												)
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</>
			) : (
				<Spinner />
			)}
			<div id='disclaimer'>
				<small>
					Disclaimer: The information and data contained herein are believed to
					be accurate and reliable. jobflo makes no warranty of any kind and
					accepts no responsibility for the results obtained through application
					of this information
				</small>
			</div>
		</React.Fragment>
	)
}

RecordsTable.propTypes = {
	job: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	getFilteredJobs: PropTypes.func.isRequired,
	getJobs: PropTypes.func.isRequired,
	getJobsHistory: PropTypes.func.isRequired,
	is_premium: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
	job: state.job,
	is_premium: state.auth.user.is_premium,
})

export default connect(mapStateToProps, {
	setAlert,
	getFilteredJobs,
	getJobs,
	getJobsHistory,
})(RecordsTable)
