import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { setAlert } from '../actions/alert'
import AuthService from '../services/auth.service'
import Alert from './layout/Alert'

const ForgotPassword = ({ isAuthenticated, setAlert }) => {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		isAuthenticated && navigate('/')
	}, [isAuthenticated])

	const handleSubmit = async (e) => {
		e.preventDefault()

		if (email !== '') {
			setLoading(true)
			try {
				const res = await AuthService.forgotPassword(email)
				setAlert(res.data.response, 'success', 'ForgotPassword')
			} catch (err) {
				setAlert(err.message, 'danger', 'ForgotPassword')
			} finally {
				setEmail('')
				setLoading(false)
			}
		} else {
			setAlert('Please enter your email', 'danger', 'ForgotPassword')
		}
	}

	return (
		<div className='container body-padding'>
			<span className='pb-3 login-title'>
				Enter your email address to restore your password
			</span>
			<Form
				style={{ maxWidth: '20rem' }}
				onSubmit={handleSubmit}
				className='login-form'
			>
				<Alert element='ForgotPassword' />
				<Form.Group className='mb-3' controlId='formHorizontalEmail'>
					<Form.Control
						value={email}
						type='email'
						placeholder='Email'
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
				</Form.Group>
				<Form.Group className='mb-3 flex justify-content-between'>
					{loading ? <Spinner /> : <Button type='submit'>Submit</Button>}
					<Link className='btn btn-secondary' to='/login'>Back</Link>
				</Form.Group>
			</Form>
		</div>
	)
}

ForgotPassword.propTypes = {
	setAlert: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, { setAlert })(ForgotPassword)
