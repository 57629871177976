import PropTypes from 'prop-types'
import React from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Navbar from 'react-bootstrap/Navbar'
import { CgPassword } from 'react-icons/cg'
import { IoIosLogOut } from 'react-icons/io'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../../actions/auth'
import Logo from '../../assets/images/Logo.png'

const Header = ({ logout, isAuthenticated, user }) => {
	return (
		<header>
			<Navbar className='header-nav' sticky='top' bg='white' expand='md'>
				<Container>
					<Navbar.Brand>
						<Link to='/'>
							<img src={Logo} alt='logo' style={{ height: '72px' }} />
						</Link>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse
						id='basic-navbar-nav'
						className='justify-content-end mr-2'
					>
						<Nav className='align-items-center col-gap-4'>
							{isAuthenticated ? (
								<NavDropdown
									title={
										user !== null && user !== undefined
											? user.first_name + ' ' + user.last_name
											: 'User name'
									}
									id='basic-nav-dropdown'
								>
									<NavDropdown.Item>
										<Link
											className='flex align-items-center col-gap-1'
											to='/profile/changepassword'
										>
											<CgPassword />
											Change password
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item
										className='flex align-items-center col-gap-1'
										onClick={logout}
									>
										<IoIosLogOut />
										Log out
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								<Nav.Item>
									<Link className='btn btn-primary' to='/login'>Sign in</Link>
								</Nav.Item>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	)
}

Header.propTypes = {
	logout: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	user: state.auth.user,
})

export default connect(mapStateToProps, { logout })(Header)
