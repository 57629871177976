import {
	AUTH_ERROR,
	LOGIN_FAIL,
	LOGIN_SUCCESS,
	LOGOUT,
	REMEMBER_ME,
	USER_LOADED
} from '../actions/types'

const initialState = {
	isAuthenticated: false,
	loading: true,
	user: {},
	remember: false,
	error: null,
}

export default function foo(state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				user: payload,
				error: null,
			}
		case LOGIN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				error: null,
			}
		case REMEMBER_ME:
			return {
				...state,
				remember: true,
			}
		case AUTH_ERROR:
			return {
				...state,
				loading: false,
				remember: false,
				error: payload ? payload : null,
			}
		case LOGIN_FAIL:
		case LOGOUT:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				user: {},
				remember: false,
				error: payload ? payload : null,
			}
		default:
			return state
	}
}
