import { format } from 'date-fns'
import ColumnFilter from './ColumnFilter'

function multiSelectFilter(rows, columnIds, filterValue) {
	return filterValue.length === 0
		? rows
		: rows.filter((row) =>
				filterValue.includes(String(row.original[columnIds]))
		  )
}

const sortItems = (prev, curr, columnId) => {
	if (columnId === 'status' || columnId === 'source') {
		if (
			prev.original[columnId][0].toLowerCase() >
			curr.original[columnId][0].toLowerCase()
		) {
			return 1
		} else if (
			prev.original[columnId][0].toLowerCase() <
			curr.original[columnId][0].toLowerCase()
		) {
			return -1
		} else {
			return 0
		}
	} else {
		if (
			prev.original[columnId].toLowerCase() >
			curr.original[columnId].toLowerCase()
		) {
			return 1
		} else if (
			prev.original[columnId].toLowerCase() <
			curr.original[columnId].toLowerCase()
		) {
			return -1
		} else {
			return 0
		}
	}
}

export const COLUMNS = [
	{
		Header: 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Source',
		accessor: 'source',
		sortType: (prev, curr, columnId) => {
			return sortItems(prev, curr, columnId)
		},
	},
	{
		Header: 'date',
		accessor: 'date',
		Filter: ColumnFilter,
		multiSelectFilter: false,
		Cell: ({ value }) => {
			return format(new Date(value), 'dd/MM/yyyy')
		},
	},
	{
		Header: 'job title',
		accessor: 'job_title',
		sortType: (prev, curr, columnId) => {
			return sortItems(prev, curr, columnId)
		},
	},

	{
		Header: 'company',
		accessor: 'company_final',
		sortType: (prev, curr, columnId) => {
			return sortItems(prev, curr, columnId)
		},
		filter: multiSelectFilter,
	},
	{
		Header: 'sector',
		accessor: 'sector.description',
		Cell: ({ value }) => {
			return <span>{value}</span>
		},
	},
	{
		Header: 'location',
		accessor: 'location_final',
		sortType: (prev, curr, columnId) => {
			return sortItems(prev, curr, columnId)
		},
		filter: multiSelectFilter,
	},
	{
		Header: 'link',
		accessor: 'url',
		Filter: ColumnFilter,
		disableFilters: true,
		Cell: ({ value }) => {
			return (
				<a
					className='row-link'
					href={value}
					target='_blank'
					rel='noopener noreferrer'
				>
					<u>view</u>
				</a>
			)
		},
	},
]
